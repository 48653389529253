<template>
  <v-container class="px-0 mb-12">
    <v-row justify="center" class="mt-9 mb-2">
      <v-col cols="12">
        <h3 class="text-center pb-3 fake-h1">
          {{ t('order_now').split(' ')[0] }} {{ t('order_now').split(' ')[1] }}
          <span class="text-secondary">{{ t('order_now').split(' ')[2] }}</span>
        </h3>
        <p class="d-flex justify-center">{{ orderNow.content }}</p>
      </v-col>
      <ClientOnly>
        <v-col
          v-if="orderNow.type === OrderNowType.ZipAndWaste"
          cols="12"
          class="px-3 custom-max-width">
          <v-icon class="position-absolute mt-7 animated-arrow">custom:polygon</v-icon>
          <form class="rounded-pill custom-shadow bg-white overflow-hidden">
            <v-row no-gutters>
              <v-col cols="12" sm="4" class="px-6 px-sm-8">
                <v-menu
                  id="menu-zipcode-order"
                  v-model="zipDropdown"
                  role="combobox"
                  aria-expanded="false"
                  aria-controls="zipInput"
                  transition="slide-y-transition">
                  <template #activator="{ props }">
                    <HeroTextfield
                      id="zipInput"
                      v-model="postalCodeString"
                      :placeholder="smAndDown ? t('enter_zip_short') : t('enter_zipcode')"
                      class="pl-2 pl-md-3 toolbar"
                      color="primary"
                      v-bind="props"
                      @focus="resetPayloadZipcode"
                      @input="checkZipInput" />
                  </template>
                  <v-list v-if="cities.length > 0">
                    <v-list-item
                      v-for="item in cities"
                      :key="item?.zip"
                      @click="selectZipcode(item)">
                      <v-list-item-title>
                        {{ `${item?.zip}, ${item?.city}` }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="4" class="pl-6 pl-sm-2 pr-3">
                <ClientOnly>
                  <GlobalWasteDropdown type="hero" @selected-waste="setPath" />
                </ClientOnly>
              </v-col>

              <v-col cols="12" sm="4" class="pa-4 pt-0 pa-sm-0">
                <v-tooltip
                  top
                  :disabled="!wrongZipcode && path !== null && path !== ''"
                  open-on-click>
                  <template #activator="{ props }">
                    <div v-bind="props">
                      <ButtonPrimary
                        color="primary"
                        class="rounded-pill toolbar-button px-0 elevation-0"
                        width="100%"
                        height="78px"
                        @click="goToPage">
                        {{ t('show_container') }}
                      </ButtonPrimary>
                    </div>
                  </template>
                  <span v-if="wrongZipcode">{{ t('enter_valid_zip') }}</span>
                  <span v-if="!wrongZipcode && (path === null || path === '')">
                    {{ t('please_choose_waste_type') }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </form>
        </v-col>

        <v-col v-else cols="12" class="px-0 d-flex justify-center">
          <ButtonPrimary class="costum-break" @click="goTo(0, goToOptions)">
            {{ t('order_now') }}
          </ButtonPrimary>
        </v-col>
      </ClientOnly>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useDisplay, useGoTo } from 'vuetify'
import { OrderNowType } from '~/types/enums'
import type { City, CityApiResponse, Fraction, OrderNow } from '~/types/types'
const { smAndDown } = useDisplay()
const goTo = useGoTo()

const { t } = useI18n()

const componentProps = defineProps<{ orderNow: OrderNow }>()

const { orderNow } = toRefs(componentProps)
const { checkZipcode } = useApiWrapper()
const offerStore = useOfferStore()
const { currentCity } = storeToRefs(offerStore)

const wrongZipcode = ref(true)
const postalCodeString = ref('')
const postalCode = ref('')
const community = ref<number | null>(null)
const cities = ref<CityApiResponse[]>([])
const city = ref('')
const path = ref('')
const zipDropdown = ref(false)
const rules = {
  required: (value: string) => !!value || 'Field is required',
  zip: (value: string) =>
    (value.length === 5 && [...value].every(item => !isNaN(parseInt(item, 10)))) ||
    'Postal code must be 5 digits',
}
const goToOptions = {
  duration: 500,
  offset: -100,
  easing: 'easeInOutCubic',
}

const setPath = (waste: Fraction) => {
  offerStore.setCurrentFractionId(waste.id)
  path.value = waste.shop_slug
}

const goToPage = async () => {
  if (rules.zip(postalCode.value) && wrongZipcode.value === false && path.value !== '') {
    offerStore.setCurrentCity({
      zip: postalCode.value,
      name: city.value,
      community: community.value as number,
    })
    return await navigateTo('/' + path.value)
  }
  return
}
const checkZipInput = () => {
  cities.value = []
  if (postalCodeString.value.length < 3 || postalCodeString.value.length > 5) {
    return
  }
  checkZipcode(postalCodeString.value).then((response: any) => {
    if (!response || response === null) {
      return
    } else {
      cities.value = response as CityApiResponse[]
      if (cities.value.length < 1) {
        wrongZipcode.value = true
      } else if (cities.value.length === 1 && postalCode.value !== cities.value[0]?.zip) {
        selectZipcode(cities.value[0])
        zipDropdown.value = false
      } else {
        zipDropdown.value = true
      }
    }
  })
}
const selectZipcode = (response: CityApiResponse) => {
  wrongZipcode.value = false
  postalCode.value = response?.zip as string
  city.value = response?.city as string
  community.value = response?.community as number
  postalCodeString.value = `${response?.zip}, ${response?.city}`
}
const resetPayloadZipcode = () => {
  postalCode.value = ''
  city.value = ''
  community.value = null
  postalCodeString.value = ''
  wrongZipcode.value = true
}

onNuxtReady(() => {
  const previousSelectedCity = currentCity.value as City
  if (previousSelectedCity?.zip) {
    cities.value = [
      {
        zip: previousSelectedCity.zip,
        city: previousSelectedCity.name,
        community: previousSelectedCity.community,
      },
    ]
    postalCode.value = previousSelectedCity.zip
    city.value = previousSelectedCity.name
    community.value = previousSelectedCity.community
    postalCodeString.value = `${previousSelectedCity.zip}, ${previousSelectedCity.name}`
    wrongZipcode.value = false
  }
})
</script>

<style lang="scss" scoped>
.animated-arrow {
  margin-left: -10px;
  animation: slideRightBack 8s infinite;
}
form {
  @media (max-width: 599px) {
    border-top-right-radius: 24px !important;
    border-top-left-radius: 24px !important;
    border-bottom-right-radius: 54px !important;
    border-bottom-left-radius: 54px !important;
  }
}
@keyframes slideRightBack {
  0% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  55% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

.toolbar:deep(svg) {
  margin-left: -17px;
}
.toolbar {
  min-height: 78px;
}

.toolbar-button:deep(span) {
  white-space: wrap !important;
  font-weight: 700;
  font-size: 1.1rem;
}
.toolbar:deep(input) {
  @media (max-width: 599px) {
    border-bottom: 1px solid #eee;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media (min-width: 600px) {
    margin-top: 15px;
    margin-bottom: -15px;
    padding-top: 5px;
    border-right: 1px solid #eee;
  }
}
.toolbar:deep(input::placeholder) {
  color: black;
  opacity: 1;
  font-weight: 700;
  font-size: 1.1rem;
}
.toolbar:deep(input:focus::placeholder) {
  opacity: 0.2;
}
.toolbar-button {
  @media (min-width: 600px) {
    border-radius: 0 !important;
  }
}
@media (max-width: 599px) {
  .toolbar:deep(input::placeholder) {
    font-size: 14px;
  }

  .toolbar-button:deep(span) {
    white-space: wrap !important;
    font-weight: 700;
    font-size: 14px;
  }
}

.costum-break {
  :deep(.v-btn__content) {
    white-space: unset;
  }
}

.v-btn.bg-primary {
  min-width: unset !important;
}

.custom-max-width {
  max-width: 800px;
}
</style>
